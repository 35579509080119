import axios from "@/axios.js";

export default {
  addItem({ commit }, item) {
    return new Promise((resolve, reject) => {
      debugger;
      axios
        .post("API/Lookup/AddOrUpdateHotLineNumber", item)
        .then(response => {
          debugger;
          resolve(response);
          commit(
            "ADD_ITEM",
            Object.assign(item, { Id: response.data.data.Id})
          );
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  fetchDataListItems({ commit }) {
    debugger;
    return new Promise((resolve, reject) => {
      axios
        .get("API/Lookup/GetHotLineNumber")
        .then(response => {
          resolve(response);
          commit("SET_HotLine", response.data.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  removeItem({ commit }, item) {
    debugger;
    return new Promise((resolve, reject) => {
      axios.delete("API/Lookup/DeleteHotLineNumber?Id="+item.id)
        .then((response) => {
          commit('REMOVE_ITEM', item.id)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

};
