<template>
    <div>
      <div id="data-list-list-view" class="data-list-container">
  
  
        <data-view-sidebar
        :isSidebarActive="addNewDataSidebar"
        @closeSidebar="toggleDataSidebar"
        :data="sidebarData"
      />
  
  
        <vs-table
          ref="table"
          id="Scroll"
          pagination
          :max-items="itemsPerPage"
          
          :data="HotLines"
        >

  
  
          <div
            slot="header"
            class="flex flex-wrap-reverse items-center flex-grow justify-between"
          >
            <vs-button type="border" icon-pack="feather" @click="addNewData">{{
              $t("AddNew")
            }}</vs-button>
  
           
          </div>
  
          <template slot="thead">
            <vs-th sort-key="NameEn">{{ $t("HotLine") }}</vs-th>
           
            <vs-th sort-key="Action">{{ $t("Action") }}</vs-th>
          </template>
  
          <template slot-scope="{ data }">
            <tbody>
              <tr
                :data="tr"
                :key="indextr"
                v-for="(tr, indextr) in data"
                @click="editData(tr)"
              >
                <vs-td>
                  <p class="product-name font-medium truncate">
                    <span >{{ tr.hotlineNumber }}</span>
                  
                  </p>
                </vs-td>
           
  
                <vs-td class="whitespace-no-wrap">
                    <feather-icon
                      icon="EditIcon"
                      @click.stop="editData(tr)"
                      svgClasses="w-5 h-5 hover:text-primary stroke-current"
                    />
                    <feather-icon
                  icon="TrashIcon"
                  svgClasses="w-10 h-5 hover:text-primary stroke-current"
                  @click.stop="openConfirm(tr)"
                />
                </vs-td>
              </tr>
            </tbody>
          </template>
        </vs-table>
      </div>
    </div>
  </template>
  
  <script>
  import { domain } from "@/gloabelConstant.js";
  import moduleHotLinel from "@/store/settings/HotLine/moduleHotLine.js";
  import DataViewSidebar from "./DataViewSidebar.vue";
  
  export default {
    components: {
      DataViewSidebar,
    },
  
    data() {
      return {
        itemsPerPage: 10,
       // HotLines: [],
        baseURL: domain,
        // Data Sidebar
        addNewDataSidebar: false,
        sidebarData: {},
        rowDataForDelete: {},
        activeConfirm: false,
      };
    },
    computed: {
      currentPage() {
        if (this.isMounted) {
          return this.$refs.table.currentx;
        }
        return 0;
      },
      HotLines() {
        debugger;
        return this.$store.state.HotLineList.HotLines;
      }
    },
  
    methods: {
      addNewData() {
        this.sidebarData = {};
        this.toggleDataSidebar(true);
      },
  
      editData(data) {
        debugger;
        this.sidebarData = data;
        this.toggleDataSidebar(true);
      },
      toggleDataSidebar(val = false) {
        this.addNewDataSidebar = val;
      },
      toggleSearchSidebar(val) {
        this.showSearchSidebar = val;
      },
  
      openConfirm(data) {
      debugger;
      this.rowDataForDelete = data;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirm`,
        text: this.$t("DeleteConfirmText"),
        accept: this.acceptAlert,
        acceptText: this.$t("Delete"),
        cancelText: this.$t("Cancel"),
      });
    },
    acceptAlert() {
      this.deleteData(this.rowDataForDelete);
      this.$vs.notify({
        color: "success",
        title: "Deleted record",
        text: this.$t("DeletedSuccessfully"),
      });

    },

    deleteData(data) {
      debugger;
      this.$store
        .dispatch("HotLineList/removeItem", data)
        .then(() => {
          this.$store.dispatch("HotLineList/fetchDataListItems");
        })
        .catch((err) => {
          console.error(err);
          this.$store.dispatch("HotLineList/fetchDataListItems");

        });
    },
    },
    created() {
      if (!moduleHotLinel.isRegistered) {
        this.$store.registerModule("HotLineList", moduleHotLinel);
        moduleHotLinel.isRegistered = true;
      }
  
      this.$store.dispatch("HotLineList/fetchDataListItems")
       
    },
    mounted() {
      this.isMounted = true;
    },
  };
  </script>
  
  <style lang="scss">
  #data-list-list-view small {
    color: rgb(255, 115, 0);
    text-decoration: underline rgb(255, 115, 0);
  }
  
  .vxCard:after {
    content: "";
    position: absolute;
    top: 0px;
    left: 0%;
    margin-left: 10%;
    z-index: 500000;
    width: 25%;
    height: 20%;
    border-top: 3px solid rgb(255, 115, 0) !important;
    border-bottom: 0 none rgba(255, 0, 0, 0.9) !important;
    border-right: 0 none rgba(255, 0, 0, 0.9) !important;
    border-left: 0 none rgba(0, 0, 0, 0.9) !important;
  }
  #data-list-list-view {
    .vs-con-table {
      .product-name {
        max-width: 23rem;
      }
  
      .vs-table--header {
        display: flex;
        flex-wrap: wrap-reverse;
        margin-left: 1.5rem;
        margin-right: 1.5rem;
        > span {
          display: flex;
          flex-grow: 1;
        }
  
        .vs-table--search {
          padding-top: 0;
  
          .vs-table--search-input {
            padding: 0.9rem 2.5rem;
            font-size: 1rem;
  
            & + i {
              left: 1rem;
            }
  
            &:focus + i {
              left: 1rem;
            }
          }
        }
      }
  
      .vs-table {
        border-collapse: separate;
        border-spacing: 0 1.3rem;
        padding: 0 1rem;
  
        tr {
          box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
          td {
            padding: 20px;
            &:first-child {
              border-top-left-radius: 0.5rem;
              border-bottom-left-radius: 0.5rem;
            }
            &:last-child {
              border-top-right-radius: 0.5rem;
              border-bottom-right-radius: 0.5rem;
            }
          }
          td.td-check {
            padding: 20px !important;
          }
        }
      }
  
      .vs-table--thead {
        th {
          padding-top: 0;
          padding-bottom: 0;
  
          .vs-table-text {
            text-transform: uppercase;
            font-weight: 600;
          }
        }
        th.td-check {
          padding: 0 15px !important;
        }
        tr {
          background: none;
          box-shadow: none;
        }
      }
  
      .vs-table--pagination {
        justify-content: center;
      }
    }
  }
  </style>
  