export default {
  ADD_ITEM(state, item) {
    debugger;
    state.HotLines.unshift(item);
  },
  SET_HotLine(state, HotLines) {
    state.HotLines = HotLines;

  },
  REMOVE_ITEM(state, itemId) {
    const ItemIndex = state.HotLines.findIndex((p) => p.ID == itemId)
    state.HotLines.splice(ItemIndex, 1)
},
};

