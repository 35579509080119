
import state from "./moduleHotLineState.js"
import mutations from "./moduleHotLineMutations.js"
import getters from "./moduleHotLineGetters.js"
import actions from './moduleHotLineActions.js';
export default {
	namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}
